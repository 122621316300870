import React from "react";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import PageBanner from "@/components/page-banner";
import ServiceDetails from "@/components/service-web-design-and-development";
import CallToActionOne from "@/components/call-to-action-one";
import MenuContextProvider from "@/context/menu-context";
import SearchContextProvider from "@/context/search-context";
import HeaderOne from "@/components/header-one";

const WebDesignAndDevelopmentPage = () => { 
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Web Design & Development Service">
          <HeaderOne />
          <PageBanner title="Website Design & Development" name="Web Design Services" />
          <ServiceDetails />
          <CallToActionOne extraClassName="ready" />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default WebDesignAndDevelopmentPage;
