import React from "react";
import serviceS1 from "@/images/web-dev.png";
import serviceS2 from "@/images/services.png";
const WebDesignAndDevelopmentContent = () => {
  return (
    <div className="serviceArea"> 
      <img src={serviceS1} alt="" />
      <h2>Website design</h2>
      <p>
      Your website's design is extremely important, not only to your website but it also has a 
      massive impact on your business. The design of your website determines how your potential 
      clients perceive your brand. That impression influences their decision to either remain on 
      your website and continue to learn about your business, products, or services, or to leave
      your page a go to a competitor's website. Good design means more engagements and more leads
      at a lower cost. Our team can redesign your existing website, or create a completely new one
      to push your business to its full potential.
    
      </p>
      <h2>Website Development</h2>
      <p>
      Once your design has your visitor's attention, the functionality of your page says a lot about your
      business. A good website needs to be easy to use and navigate. It's also important that your website 
      is reliable and fast. When developed correctly a website helps your brand to build trust and credibility. 
      That's why when we develop a website we use the latest industry-leading technologies and techniques to 
      ensure your website is cutting-edge. We ensure our websites are always fast, reliable, and function 
      optimally to allow your business to make the best first impression possible time after time.
      </p>
      <div className="row gaping"> 
        <div className="col-lg-6 col-sm-12 col-md-6"> 
          <img src={serviceS2} alt="" />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-6">
          <h3>Our Process</h3>
          <p>
            A breif overview of the process we go through on every project.
          </p>
          <ul>
            <li>
              <i className="fa fa-check-square"></i>Discovery Phase 
            </li>
            <br></br>
            <li>
              <i className="fa fa-check-square"></i>Planning Phase
            </li>
            <br></br>
            <li>
              <i className="fa fa-check-square"></i>Design Phase
            </li>
            <br></br>
            <li>
              <i className="fa fa-check-square"></i>Development Phase
            </li>
            <br></br>
            <li>
              <i className="fa fa-check-square"></i>Testing, Quality Control & Approval
            </li>
          </ul>
        </div>
      </div>
      <p>
        During the discovery phase, our team will discuss all your needs, requirements, and ideas for your project.
        We will offer advice and guidance on how to get the most out of your website. When our team understands all 
        of your requirements they will begin the planning phase where they will decide how to best achieve your goals. 
        Then the design team will begin to craft your website, ensuring that it is optimised for user experience and 
        engagement. After you are happy with the design we will begin to develop your website.

      </p>
      <p>
      Once development begins a member of our team will continue to keep you updated on the progress of your project.
       Our development team always uses the latest industry-leading techniques while writing your code. 
       This ensures your code is easily maintainable and is optimised to keep your website fast and reliable. 
       As this stage ends the project is handed over to our brilliant testing and quality control team, 
       they ensure every part of your website is perfect and ready to impress.
      </p>
    </div>
  );
};

export default WebDesignAndDevelopmentContent;
